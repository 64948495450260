<template>
  <div class="fluid-container pt-3 pb-3" style="background: #0000ff !important">
    <div class="container">
      <div class="row g-0 d-flex">
        <div class="col-12">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid ps-0 pe-0">
              <div class="d-flex flex-row">
                <router-link to="/" class="navbar-brand white" tag="a"
                  ><img
                    src="../assets/resla-logo-white-rgb-600px@300ppi.png"
                    height="15vh"
                /></router-link>
              </div>
              <div class="d-flex flex-row d-lg-none d-xl-none">
                <router-link
                  tag="button"
                  to="/reservations"
                  class="btn btn-secondary d-xl-none pr-1 rounded-pill"
                >
                  Book now
                </router-link>
                <button
                  class="btn btn-transparent"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z"
                      fill="#fff"
                    />
                  </svg>
                </button>
              </div>
              <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ms-auto mb-2 me-3 mb-md-0">
                  <li class="nav-item ps-2 pe-2 small">
                    <a class="nav-link white" href="https://www.resla.com/#home"
                      >Home</a
                    >
                  </li>
                  <li class="nav-item ps-2 pe-2 small">
                    <a
                      class="nav-link white"
                      href="https://www.resla.com/#models"
                      >Models</a
                    >
                  </li>
                  <li class="nav-item ps-2 pe-2 small">
                    <a
                      class="nav-link white"
                      href="https://www.resla.com/#how-it-works"
                      >How It Works</a
                    >
                  </li>
                  <li class="nav-item ps-2 pe-2 small">
                    <a class="nav-link white" href="https://www.resla.com/faqs"
                      >FAQs</a
                    >
                  </li>
                  <li class="nav-item ps-2 pe-2 small">
                    <a
                      class="nav-link white"
                      href="https://www.resla.com/contact"
                      >Contact Us</a
                    >
                  </li>
                </ul>
                <router-link
                  tag="button"
                  to="/reservations"
                  class="btn btn-secondary d-none d-lg-flex rounded-pill"
                >
                  Book now
                </router-link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>
