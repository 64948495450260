<template>
  <div class="fluid-container">
    <div class="row g-0 blue d-sm-none">
      <div class="col-12">
        <h3 class="pt-4 pb-4 text-center white">
          The easiest way to rent a car
        </h3>
      </div>
    </div>
    <!-- Mobile -->
    <div class="row g-0 p-3 d-sm-none">
      <div class="col-12">
        <h1 class="display-3 mt-3 mb-5">
          <span class="highlighted">How it works.</span>
        </h1>

        <ul class="timeline-with-icons">
          <li class="timeline-item mb-5">
            <span class="timeline-icon h6 fw-bold p-1"> 01. </span>
            <h3>
              Book a Tesla<br />
              in your desired<br />
              location
            </h3>
          </li>
          <li class="timeline-item mb-5">
            <span class="timeline-icon h6 fw-bold p-1"> 02. </span>
            <h3>
              Collect your Resla<br />
              using our digital key
            </h3>
          </li>
          <li class="timeline-item mb-5">
            <span class="timeline-icon h6 fw-bold p-1"> 03. </span>
            <h3>
              Enjoy your trip<br />
              with <b>zero hassle</b>
            </h3>
          </li>
          <li class="timeline-item mb-5">
            <span class="timeline-icon h6 fw-bold p-1"> 04. </span>
            <h3>
              Return the Tesla<br />
              at your convenience
            </h3>
          </li>
        </ul>
      </div>
    </div>
    <!-- Desktop -->
    <div class="container">
      <div class="row g-0 pt-3 pb-3 d-none d-sm-block" id="how-it-works">
        <div class="col-12">
          <h1 class="display-5 mt-5 mb-5">
            <span class="highlighted">How it works.</span>
          </h1>

          <ul class="row g-0 mt-3">
            <li class="mb-5 mt-3 col-2 list-unstyled">
              <h5>
                <span class="fw-bold p-1 ps-0 primary-color"> 01. </span>
                Book a Tesla <br />in your desired location
              </h5>
            </li>
            <li class="mb-5 mt-3 col-1 list-unstyled">
              <div class="row g-0 mt-2 pe-3">
                <div class="col-auto my-auto"></div>
                <div class="col my-auto border tertiary-border"></div>
              </div>
            </li>
            <li class="mb-5 mt-3 col-2 list-unstyled">
              <h5>
                <span class="fw-bold p-1 ps-0 primary-color"> 02. </span>
                Collect your Resla using our digital key
              </h5>
            </li>
            <li class="mb-5 mt-3 col-1 list-unstyled">
              <div class="row g-0 mt-2 pe-3">
                <div class="col-auto my-auto"></div>
                <div class="col my-auto border tertiary-border"></div>
              </div>
            </li>
            <li class="mb-5 mt-3 col-2 list-unstyled">
              <h5>
                <span class="fw-bold p-1 ps-0 primary-color"> 03. </span>
                Enjoy your trip with <b>zero hassle</b>
              </h5>
            </li>
            <li class="mb-5 mt-3 col-1 list-unstyled">
              <div class="row g-0 mt-2 pe-3">
                <div class="col-auto my-auto"></div>
                <div class="col my-auto border tertiary-border"></div>
              </div>
            </li>
            <li class="mb-5 mt-3 col-2 list-unstyled">
              <h5>
                <span class="fw-bold p-1 ps-0 primary-color"> 04. </span>
                Return the <br />Tesla at your<br />
                convenience
              </h5>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorksSection",
};
</script>
