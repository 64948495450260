<template>
  <div>
    <!-- Mobile -->
    <div class="fluid-container d-sm-none">
      <div class="row g-0 mt-3 mb-3 p-3">
        <div class="col-12">
          <h3 class="pt-4 pb-4 primary-color">
            Offering a great rental experience doesn’t have to be rocket science.
          </h3>
          <h5>
            Our Teslas have the latest features, including
            <span class="primary-color"
              ><a
                class="primary-color"
                target="_blank"
                style="text-decoration: none !important"
                href="https://www.resla.com/blog/tesla-autopilot-navigating-the-road-to-self-driving"
                >autopilot.</a
              ></span
            >
          </h5>
        </div>
      </div>
      <div class="row g-0">
        <div
          id="carouselExampleIndicators2"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row g-0">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <img
                        src="../../assets/img/interior/Model3_01.png"
                        class="card-img-top rounded"
                        alt="..."
                      />
                      <!--<h3 class="card-title text-center fw-normal mt-3">
                        Model Y
                      </h3>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row g-0">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <img
                        src="../../assets/img/interior/Model3_03.png"
                        class="card-img-top rounded"
                        alt="..."
                      />
                      <!-- <h3 class="card-title text-center fw-normal mt-3">
                        Model Y
                      </h3>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row g-0">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <img
                        src="../../assets/img/interior/Model3_04.png"
                        class="card-img-top rounded"
                        alt="..."
                      />
                      <!-- <h3 class="card-title text-center fw-normal mt-3">
                        Model Y
                      </h3>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="carousel-indicators mt-3">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>

            <button
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators2"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <!-- Desktop -->
    <div class="fluid-container d-none d-sm-flex">
      <div class="container">
        <div class="row g-0 mt-3 mb-3">
          <div class="col-6">
            <!-- Move to next section for desktop -->
            <div class="row g-0">
              <div class="col-12">
                <div class="card rounded-3 shadow w-75 mx-auto">
                  <div class="card-body">
                    <h3
                      class="card-title primary-color text-center mb-4 mt-3 font-weight-bold fw-600"
                    >
                      Why us?
                    </h3>
                    <h6 class="mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="#0000FF"
                        /></svg
                      ><span class="ms-2">Contactless collection</span>
                    </h6>
                    <p class="text-muted text-decoration-line-through small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="white"
                        /></svg
                      ><span class="ms-2">In-person collection</span>
                    </p>
                    <h6 class="mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="#0000FF"
                        /></svg
                      ><span class="ms-2">24/7 pick-up access </span>
                    </h6>
                    <p class="text-muted text-decoration-line-through small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="white"
                        /></svg
                      ><span class="ms-2">Set pick-up hours</span>
                    </p>
                    <h6 class="mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="#0000FF"
                        /></svg
                      ><span class="ms-2">Guaranteed Tesla</span>
                    </h6>
                    <p class="text-muted text-decoration-line-through small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="white"
                        /></svg
                      ><span class="ms-2">No car guarantee</span>
                    </p>
                    <h6 class="mt-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="#0000FF"
                        /></svg
                      ><span class="ms-2">Transparent pricing</span>
                    </h6>
                    <p class="text-muted text-decoration-line-through small">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="1.5rem"
                        height="1.5rem"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                          fill="white"
                        /></svg
                      ><span class="ms-2">Confusingly high fees</span>
                    </p>
                  </div>
                </div>
                <h5 class="w-75 mx-auto mt-4 primary-color fw-600">
                  Offering a great rental experience doesn’t have to be rocket science.
                </h5>
              </div>
            </div>
          </div>
          <div class="col-6">
            <h5>
              Our Teslas have the latest features, including
              <span class="primary-color"
                ><a
                  class="primary-color"
                  target="_blank"
                  style="text-decoration: none !important"
                  href="https://www.resla.com/blog/tesla-autopilot-navigating-the-road-to-self-driving"
                  >autopilot.</a
                ></span
              >
            </h5>

            <div
              id="carouselExampleIndicators3"
              class="carousel slide mt-4"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row g-0">
                    <div class="col-10 offset-1">
                      <div class="card">
                        <img
                          src="../../assets/img/interior/Model3_01.png"
                          class="card-img-top rounded"
                          alt="..."
                        />
                        <!--<h3 class="card-title text-center fw-normal mt-3">
                        Model Y
                      </h3>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row g-0">
                    <div class="col-10 offset-1">
                      <div class="card">
                        <img
                          src="../../assets/img/interior/Model3_03.png"
                          class="card-img-top rounded"
                          alt="..."
                        />
                        <!--<h3 class="card-title text-center fw-normal mt-3">
                        Model Y
                      </h3>-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row g-0">
                    <div class="col-10 offset-1">
                      <div class="card">
                        <img
                          src="../../assets/img/interior/Model3_04.png"
                          class="card-img-top rounded"
                          alt="..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-indicators mt-3">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators3"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fluid-container d-none d-sm-block">
      <div class="row g-0 mt-5 mb-5">
        <div class="col-6 offset-6 border secondary-border"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureSection",
};
</script>
