<template>
  <div>
    <MainSection></MainSection>
    <ModelsSection></ModelsSection>
    <HowItWorksSection></HowItWorksSection>
    <CompetitionSection></CompetitionSection>
    <FeatureSection></FeatureSection>
    <FaqsSection></FaqsSection>
    <ReviewSection></ReviewSection>
  </div>
</template>

<script>
// @ is an alias to /src
import MainSection from "@/components/home/MainSection.vue";
import FeatureSection from "@/components/home/FeatureSection.vue";
import ModelsSection from "@/components/home/ModelsSection.vue";
import HowItWorksSection from "@/components/home/HowItWorksSection.vue";
import CompetitionSection from "@/components/home/CompetitionSection.vue";
import FaqsSection from "@/components/home/FaqsSection.vue";
import ReviewSection from "@/components/home/ReviewSection.vue";

export default {
  name: "HomeView",
  components: {
    FeatureSection,
    MainSection,
    ModelsSection,
    HowItWorksSection,
    CompetitionSection,
    FaqsSection,
    ReviewSection
  },
};
</script>
