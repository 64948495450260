<template>
  <div>
    <!-- Mobile -->
    <div class="fluid-container d-sm-none">
      <h1 class="display-3 mt-3 mb-0 pb-0 w-100">
        <span class="highlighted w-100">So what’s the catch?</span>
      </h1>
      <div class="row g-0 blue">
        <div class="col-12">
          <h3 class="pt-4 pb-4 text-center white">
            No catch. We were just tired of complicated car
            rentals, so we decided to make the process easy and simple.
          </h3>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-12">
          <div class="card border w-75 mx-auto mt-5">
            <div class="card-body">
              <h1 class="card-title mt-3 mb-3 text-center">Resla</h1>
              <h5 class="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                    fill="blue"
                  /></svg
                ><span class="ms-2">Contactless collection</span>
              </h5>
              <h5 class="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                    fill="blue"
                  /></svg
                ><span class="ms-2">24/7 pick-up access </span>
              </h5>
              <h5 class="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                    fill="blue"
                  /></svg
                ><span class="ms-2">Guaranteed Tesla</span>
              </h5>
              <h5 class="mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="1.5rem"
                  height="1.5rem"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-.997-4L6.76 11.757l1.414-1.414 2.829 2.829 5.656-5.657 1.415 1.414L11.003 16z"
                    fill="blue"
                  /></svg
                ><span class="ms-2">Transparent pricing</span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Desktop -->
    <div class="fluid-container d-none d-sm-block">
      <div class="row g-0">
        <div class="col-8">
          <div class="row g-0">
            <div class="col-6 my-auto border secondary-border"></div>
            <div class="col-auto my-auto">
              <h3 class="badge fw-400" style="font-size: unset !important">
                So what's the catch?
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row g-0">
          <div class="col-8"></div>
        </div>

        <div class="row g-0">
          <div class="col-6 p-3">
            <h5 class="mt-3">
              No catch. We were just tired of <br />complicated car
              rentals, so we decided to make <br />the process easy and simple.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompetitionSection",
};
</script>
