<template>
  <div class="fluid-container black">
    <footer class="py-5 pt-3">
      <div class="row g-0 d-flex">
        <div class="col-4 offset-1 py-2 pb-2 d-none d-md-block">
          <img
            src="../assets/resla-logo-white-rgb-600px@300ppi.png"
            height="15vh"
          />
        </div>
        <div class="col-10 offset-1 py-2 pb-2 d-md-none text-center mt-3">
          <img
            src="../assets/resla-logo-white-rgb-600px@300ppi.png"
            height="15vh"
            class="mb-3"
          />
        </div>
        <div class="col-auto ms-auto my-auto d-none d-md-block">
          <div class="text-center">
            <a
              href="https://www.facebook.com/rentwithresla"
              target="_blank"
              class="ms-auto me-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="white"
                  d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/rentwithresla/"
              target="_blank"
              class="me-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="white"
                  d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                /></svg
            ></a>
            <a
              href="https://www.linkedin.com/company/rentwithresla/"
              target="_blank"
              class="me-1"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="white"
                  d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"
                /></svg
            ></a>
            <a
              href="https://www.youtube.com/channel/UCh6nstSu8JgJ0zg7CdnxZqg"
              target="_blank"
              class="me-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="white"
              >
                <path
                  d="M12.2439 4C12.778 4.00294 14.1143 4.01586 15.5341 4.07273L16.0375 4.09468C17.467 4.16236 18.8953 4.27798 19.6037 4.4755C20.5486 4.74095 21.2913 5.5155 21.5423 6.49732C21.942 8.05641 21.992 11.0994 21.9982 11.8358L21.9991 11.9884L21.9991 11.9991C21.9991 11.9991 21.9991 12.0028 21.9991 12.0099L21.9982 12.1625C21.992 12.8989 21.942 15.9419 21.5423 17.501C21.2878 18.4864 20.5451 19.261 19.6037 19.5228C18.8953 19.7203 17.467 19.8359 16.0375 19.9036L15.5341 19.9255C14.1143 19.9824 12.778 19.9953 12.2439 19.9983L12.0095 19.9991L11.9991 19.9991C11.9991 19.9991 11.9956 19.9991 11.9887 19.9991L11.7545 19.9983C10.6241 19.9921 5.89772 19.941 4.39451 19.5228C3.4496 19.2573 2.70692 18.4828 2.45587 17.501C2.0562 15.9419 2.00624 12.8989 2 12.1625V11.8358C2.00624 11.0994 2.0562 8.05641 2.45587 6.49732C2.7104 5.51186 3.45308 4.73732 4.39451 4.4755C5.89772 4.05723 10.6241 4.00622 11.7545 4H12.2439ZM9.99911 8.49914V15.4991L15.9991 11.9991L9.99911 8.49914Z"
                ></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/rentwithresla"
              target="_blank"
              class="me-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="white"
              >
                <path
                  d="M18.2048 2.25H21.5128L14.2858 10.51L22.7878 21.75H16.1308L10.9168 14.933L4.95084 21.75H1.64084L9.37084 12.915L1.21484 2.25H8.04084L12.7538 8.481L18.2048 2.25ZM17.0438 19.77H18.8768L7.04484 4.126H5.07784L17.0438 19.77Z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-10 offset-1 border-bottom grey-border"></div>
        <div
          class="col-10 offset-1 border-bottom grey-border py-2 pb-2 text-center white"
        >
          <div class="col-12 d-md-none">
            <div class="text-center mb-1">
              <a
                href="https://www.facebook.com/rentwithresla"
                target="_blank"
                class="ms-auto me-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="white"
                    d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/rentwithresla/"
                target="_blank"
                class="me-1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="white"
                    d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                  /></svg
              ></a>
              <a
                href="https://www.linkedin.com/company/rentwithresla/"
                target="_blank"
                class="me-1"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="white"
                    d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"
                  /></svg
              ></a>
            </div>
          </div>
          <a href="mailto:support@resla.com" class="text-white"
            >support@resla.com</a
          >
          | <a href="tel:+14804394079" class="text-white">(480) 439-4079</a>
        </div>

        <div class="col-10 offset-1 py-2 pb-2 text-center small">
          Resla © 2022 | All rights reserved.
        </div>

        <div class="col-12">
          <ul class="nav text-center mt-3 justify-content-center small">
            <li class="nav-item">
              <a href="https://www.resla.com/#home" class="nav-link white"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.resla.com/#how-it-works"
                class="nav-link white"
                >How It Works</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/#models" class="nav-link white"
                >Models</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/faqs" class="nav-link white"
                >FAQs</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/blog" class="nav-link white"
                >Blog</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/careers" class="nav-link white"
                >Careers</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/contact" class="nav-link white"
                >Contact Us</a
              >
            </li>
            <li class="nav-item">
              <a href="https://www.resla.com/sales" class="nav-link white"
                >For Sale</a
              >
            </li>
            <li class="nav-item">
              <router-link to="/disclaimers" tag="a" class="nav-link white"
                >Disclaimers</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/privacy" tag="a" class="nav-link white"
                >Privacy</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/terms" tag="a" class="nav-link white"
                >Terms</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/policies" tag="a" class="nav-link white"
                >Policies</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>
